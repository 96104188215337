// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"

// To polyfill for gatsby-background-image, from : https://www.gatsbyjs.org/packages/gatsby-background-image/#install
// --
// ES5 way
// exports.onClientEntry = () => {
// ES6 way
export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (typeof window.IntersectionObserver === `undefined`) {
    console.log(`# IntersectionObserver is polyfilled!`)
    return import(`intersection-observer`)
  }
}
